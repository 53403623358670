<template>
	<div class="main">
		<!--顶部tap开始-->
		<div class="top-tap-box">
			<div class="top-tap-item" v-for="(item, index) in addData.contents" :key="index">
				<div  :class="index === tempIndex ? 'top-tap-item-select' : 'top-tap-item-def'">
					<div class="top-tap-text"  @click="changeTapIndex(index)">
						{{item.target?item.target:'请输入指标名称'}}
					</div>
				</div>
				<div class="span-line-icon"></div>
			</div>
		</div>
		<!--顶部tap结束-->
		<div class="app-box">
			<!--人事评分开始-->
			<div v-if="stepId >1" class="">
				<div class="top-title-box">
					<div class="top-title-left">
						<img style="width: 15px;height: 15px;padding-right: 5px" src="../../assets/image/performance/pf.png"/>
						<div class="app-title-text">总部人事评分</div>
					</div>
					<div v-if="stepId > 2" class="top-title-right-ok">已完成评分</div>
					<div v-else class="top-title-right-no">未完成评分</div>
				</div>
				<div class="item-box">
					<div class="item-title">
						<div class="item-star">*</div>
						<div>总部人事评分：</div>
					</div>
					<div class="item-input">
						<input :disabled="stepId > 2" type="number" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="postData.contents[tempIndex].hrRatingScore" placeholder="请输入" />
					</div>
				</div>
				<div class="item-box-field">
					<div class="item-title-file">
						<div class="item-star">  </div>
						<div>总部人事评价：</div>
					</div>
				</div>
				<div class="file-box">
					<van-field
							:disabled="stepId > 2"
							style="font-size: 16px"
							v-model="postData.contents[tempIndex].hrEval"
							rows="3"
							autosize
							type="textarea"
							maxlength="150"
							placeholder="请输入"
							show-word-limit
					/>
				</div>
			</div>
			<!--人事评分结束-->
			<!--领导评分开始-->
			<div v-if="stepId > 0" class="app-box-item">
				<div class="top-title-box">
					<div class="top-title-left">
						<img style="width: 15px;height: 15px;padding-right: 5px" src="../../assets/image/performance/pf.png"/>
						<div class="app-title-text">领导评分</div>
					</div>
					<div v-if="stepId > 1" class="top-title-right-ok">已完成评分</div>
					<div v-else class="top-title-right-no">未完成评分</div>
				</div>
				<div class="item-box">
					<div class="item-title">
						<div class="item-star">*</div>
						<div>领导评分：</div>
					</div>
					<div class="item-input">
						<input :disabled="stepId > 1" type="number" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="postData.contents[tempIndex].leaderRatingScore" placeholder="请输入" />
					</div>
				</div>
				<div class="item-box-field">
					<div class="item-title-file">
						<div class="item-star">  </div>
						<div>领导评价：</div>
					</div>
				</div>
				<div class="file-box">
					<van-field
							:disabled="stepId > 1"
							style="font-size: 16px"
							v-model="postData.contents[tempIndex].leaderEval"
							rows="3"
							autosize
							type="textarea"
							maxlength="150"
							placeholder="请输入"
							show-word-limit
					/>
				</div>
			</div>
			<!--领导评分结束-->
			<!--考核结果自评开始-->
			<div class="app-box-item">
				<div class="top-title-box">
					<div class="top-title-left">
						<img style="width: 15px;height: 15px;padding-right: 5px" src="../../assets/image/performance/jx.png"/>
						<div class="app-title-text">考核结果自评</div>
					</div>
					<div v-if="stepId > 0" class="top-title-right-ok">已完成评分</div>
					<div v-else class="top-title-right-no">未完成评分</div>
				</div>
				<div class="app-tip-text">注意：自评分数作为绩效考核面谈的参考依据，不占得分比例。</div>
				<div class="item-box">
					<div class="item-title">
						<div class="item-star">*</div>
						<div>自评得分：</div>
					</div>
					<div class="item-input">
						<input :disabled="stepId > 0" type="number" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="postData.contents[tempIndex].selfRatingScore" placeholder="请输入" />
					</div>
				</div>
				<div class="item-box-field">
					<div class="item-title-file">
						<div class="item-star">*</div>
						<div>任务完成情况：</div>
					</div>
				</div>
				<div class="file-box">
					<van-field
							style="font-size: 16px"
							v-model="postData.contents[tempIndex].selfEval"
							rows="3"
							:disabled="stepId > 0"
							autosize
							type="textarea"
							maxlength="150"
							placeholder="请输入任务完成情况"
							show-word-limit
					/>
				</div>
			</div>
			<!--考核结果自评结束-->
		</div>
		<div style="width: 100vw;height: 10px;background-color: #F1F1F1"></div>
		<div class="add-item-box">
			<div class="item-box">
				<div class="item-title">
					<div>指标名称：</div>
				</div>
				<div class="item-input">
					<span>{{addData.contents[tempIndex].target}}</span>
				</div>
			</div>
			<div class="item-box">
				<div class="item-title">
					<div>权重：</div>
				</div>
				<div class="item-input">
					<span v-if="addData.contents[tempIndex].weight">{{addData.contents[tempIndex].weight}}%</span>
					<span v-else class="pla-color">请选择</span>
					<van-icon color="#999999" name="arrow" />
				</div>
			</div>
			<div class="item-box-field">
				<div class="item-title-file">
					<div>定义及计算方式：</div>
				</div>
			</div>
			<div class="file-box">
				<van-field
						style="font-size: 16px"
						disabled
						v-model="addData.contents[tempIndex].computeMode"
						rows="3"
						autosize
						type="textarea"
						maxlength="150"
						placeholder="请输入定义及计算方式"
						show-word-limit
				/>
			</div>
			<div class="item-box">
				<div class="item-title">
					<div>目标值：</div>
				</div>
				<div class="item-input">
					<span>{{addData.contents[tempIndex].targetValue}}</span>
				</div>
			</div>
			<div class="item-box">
				<div class="item-title">
					<div>数据来源：</div>
				</div>
				<div class="item-input">
					<span>{{addData.contents[tempIndex].source}}</span>
				</div>
			</div>
			<div class="item-box-field">
				<div class="item-title">
					<div>备注：</div>
				</div>
				<div class="item-input">
				</div>
			</div>
			<div class="file-box">
				<van-field
						style="font-size: 16px"
						disabled
						v-model="addData.contents[tempIndex].remarks"
						rows="3"
						autosize
						type="textarea"
						maxlength="150"
						placeholder="请输入备注"
						show-word-limit
				/>
			</div>
		</div>
		<!--底部菜单开始-->
		<div class="bottom-box">
			<van-grid :column-num="2">
				<van-grid-item @click="backIndex">
					<span style="color: #f6404f">取消</span>
				</van-grid-item>
				<van-grid-item @click="saveData">
					<span style="color: #5DA3FA">保存</span>
				</van-grid-item>
			</van-grid>
		</div>
		<!--底部菜单结束-->
		<!--选中权重开始-->
		<van-overlay :show="showOverlay" />
		<div v-show="showSelect" class="picker-box">
			<van-picker
					title="标题"
					show-toolbar
					:columns="qzOptions"
					@confirm="onConfirm"
					@cancel="onCancel"
			/>
		</div>
		<!--选择权重结束-->
	</div>
</template>

<script>
	import { getPerfById } from '@/api/performance'
	import {Dialog} from 'vant';
	// import Cookies from "js-cookie";
	export default {
		name: 'addPref',
		data() {
			return {
				id: '',
				addData: {
					id: '',
					submitType: '',
					modifyType: '',
					images: '',
					months: '',
					contents: [
						{
							id: '',
							assessId: '',
							computeMode: '',
							rater: '',
							raterName: '',
							remarks: '',
							source: '',
							target: '',
							targetValue: '',
							weight: ''
						}
					],
				},
				tempIndex: 0, // 当前选中的条目
				isMargin: '', // 判断是否添加顶部展示class
				qzOptions: [],
				showSelect: false, // 是否展示选择权重
				showOverlay: false, // 是否显示遮罩
				month: '',
				postData: {
					assessId:'', //评分id
					hrSubtract: '', // hr加减分
					supSubtract:'', // 上级领导加减分
					contents:[
						{
							id:'',
							selfEval:'', // 自评
							selfRatingScore:'', // 自评得分
							taskResponse:'', // 任务完成情况
							leaderRatingScore:'', // 领导评分
							leaderEval:'', // 领导评语
							hrRatingScore:'', // hr评分
							hrEval:'' // hr评语
						}
					]
				},
				stepId: 0, // 评分步骤
				selfDis: false
			};
		},
		created() {
			const query = this.$route.query
			this.initQz()
			if (!query.id || !query.stepId) {
				Dialog.alert({title: '提示', message: '参数错误',})
				return
			}
			let tempData = sessionStorage.getItem('approvalData')
			if(tempData){
				this.postData = JSON.parse(tempData)
			}
			// 判断是否登录
			if(!sessionStorage.getItem("token")){
				//存储路由
				sessionStorage.setItem("perfPath", this.$route.path)
				sessionStorage.setItem("perfQuery", JSON.stringify(query))
				const postData = {}
				this.$router.push({ path: '/perfLogin', query: postData})
				return
			}
			this.id = query.id
			this.stepId = query.stepId
			this.getInfo()
		},
		methods: {
			// 格式化权重配置
			initQz() {
				let i = 5
				while (i <= 100) {
					const tempData = i + '%'
					this.qzOptions.push(tempData)
					i = i + 5
				}
			},
			// 获取信息
			getInfo(){
				const postData = {}
				postData.id = this.id
				getPerfById(postData).then((res) => {
					this.addData.contents = res.data.detailFormList
				})
			},
			// 保存
			saveData(){
				console.log(this.stepId)
				console.log(this.postData.contents)
				// 验证评分是否有超过100分的
				for(let i = 0;i<this.postData.contents.length;i++){
					if(parseInt(this.stepId) === 0){
						if(this.postData.contents[i].selfRatingScore === ''){
							Dialog.alert({title: '提示', message: '必填项未全部填写完毕，请补充后重新提交！',})
							return
						}
						if(this.postData.contents[i].selfEval === ''){
							Dialog.alert({title: '提示', message: '必填项未全部填写完毕，请补充后重新提交！',})
							return
						}
					}
					if(parseInt(this.stepId) === 1){
						if(this.postData.contents[i].leaderRatingScore === ''){
							Dialog.alert({title: '提示', message: '必填项未全部填写完毕，请补充后重新提交！',})
							return
						}
					}
					if(parseInt(this.stepId) === 2){
						if(this.postData.contents[i].hrRatingScore === ""){
							Dialog.alert({title: '提示', message: '必填项未全部填写完毕，请补充后重新提交！',})
							return
						}
					}
					if(this.postData.contents[i].selfRatingScore < 0 || this.postData.contents[i].selfRatingScore > 100){
						Dialog.alert({title: '提示', message: '评分必须大于0小于100',})
						return
					}
					if(this.postData.contents[i].leaderRatingScore < 0 || this.postData.contents[i].leaderRatingScore > 100){
						Dialog.alert({title: '提示', message: '评分必须大于0小于100',})
						return
					}
					if(this.postData.contents[i].hrRatingScore < 0 || this.postData.contents[i].hrRatingScore > 100){
						Dialog.alert({title: '提示', message: '评分必须大于0小于100',})
						return
					}
				}
				sessionStorage.setItem('approvalData',JSON.stringify(this.postData))
				const postData = {}
				postData.id = this.id
				this.$router.push({ path: '/appIndex', query: postData})
			},
			// 返回首页
			backIndex(){
				const postData = {}
				postData.id = this.id
				this.$router.push({ path: '/appIndex', query: postData})
			},
			// 顶部tap切换
			changeTapIndex(index){
				this.tempIndex = index
			},
			// 展示选择权重
			showSelectTarget(){
				this.showSelect = true
				this.showOverlay = true
			},
			// 确认选择权重
			onConfirm(value, index) {
				console.log(value)
				console.log(index)
				this.showSelect = false
				this.showOverlay = false
				this.addData.contents[this.tempIndex].weight = value.replace('%', '')
			},
			// 点击取消
			onCancel() {
				this.showSelect = false
				this.showOverlay = false
			},
		},
	};

</script>

<style scoped>
	input{
		border: none !important;
		text-align: right;
	}
	::-webkit-input-placeholder{color:#999999;}
	::-moz-placeholder{color:#999999;}
	:-moz-placeholder{color:#999999;}
	.add-item-box{
		background-color: #ffffff;
	}
	.app-box{
		margin-top: 50px;
		margin-bottom: 15px;
		background-color: #ffffff;
		padding: 15px 0;
	}
	.app-box-item{
		padding-top: 20px;
	}
	.top-title-box{
		display: flex;
		justify-content: space-between;
		padding: 0 15px;
	}
	.top-title-left{
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.app-title-text{
		font-weight: bold;
	}
	.top-title-right-no{
		background: #FFEFEE;
		border-radius: 4px;
		color: #FA7A6F;
		font-size: 15px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		padding: 0 15px;
	}
	.top-title-right-ok{
		background: #EDFFF8;
		border-radius: 4px;
		color: #28B57F;
		font-size: 15px;
		height: 28px;
		line-height: 28px;
		text-align: center;
		padding: 0 15px;
	}
	.app-tip-text{
		color: #999999;
		border-bottom: 1px solid #F1F1F1;
		font-size: 14px;
		padding: 15px;
	}
	.item-box{
		display: flex;
		justify-content: space-between;
		padding: 15px;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #F1F1F1;
	}
	.item-box-field{
		padding: 15px;
	}
	.item-title{
		display: flex;
		align-items: center;
		width: calc(40vw - 15px);
	}
	.item-title-file{
		display: flex;
		align-items: center;
		width: calc(100vw - 30px);
	}
	.item-star{
		padding-right: 6px;
		color: #F76161;
	}
	.item-input{
		width: calc(60vw - 15px);
		text-align: right;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.pla-color{
		color: #999999;
		font-size: 16px;
	}
	.file-box{
		font-size: 16px;
		border-bottom: 1px solid #F1F1F1;
	}
	.bottom-box{
		position: fixed;
		bottom: 0;
		font-size: 16px;
		width: 100vw;
	}
	.top-tap-box{
		position: fixed;
		top: 0;
		overflow: scroll;
		display: flex;
		justify-content: flex-start;
		white-space: nowrap;
		width: 100%;
		background-color: #ffffff;
		z-index: 99;
		border-bottom: 1px solid #F1F1F1;
	}
	.top-tap-item-def{
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		margin: 0 10px;
		text-align: center;
	}
	.top-tap-item-select{
		height: 50px;
		line-height: 50px;
		font-size: 14px;
		border-bottom: 2px solid #5DA3FA;
		background-color: #ffffff;
		color: #5DA3FA;
		margin: 0 10px;
		text-align: center;
	}
	.top-tap-item{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.span-line-icon{
		width: 1px;
		height: 50px;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #DDDDDD 53%, rgba(255,255,255,0) 100%);
		border-radius: 2px;
	}
	.picker-box{
		position: fixed;
		bottom: 0;
		width: 100vw;
		z-index: 100;
	}
	input:disabled{
		background-color: #ffffff;
		color:#ACA899;

	}
</style>
